import React, { useEffect } from 'react'
import { TweenMax } from 'gsap'

const Unicorn = () => {
   useEffect(() => {
      TweenMax.to('#horn-0', 1.5, { fill: '#D9033C', delay: 0.8 })
      TweenMax.to('#horn-1', 1.5, { fill: '#FFD300', delay: 0.6 })
      TweenMax.to('#horn-2', 1.5, { fill: '#d3ff75', delay: 0.4 })
      TweenMax.to('#horn-3', 1.5, { fill: '#59ffba', delay: 0.3 })
      TweenMax.to('#horn-4', 1.5, { fill: '#73deff', delay: 0.2 })
      TweenMax.to('#horn-5', 1.5, { fill: '#8387f7', delay: 0.1 })

      TweenMax.to('#mane-0-a', 2, {
         attr: {
            d:
               'm32.3 17.8 c0 0 4.2 -7.1 20.2 -9.3 -15.3 -2 -23.5 6.6 -23.5 6.6',
         },
         repeat: -1,
         yoyo: true,
      })

      TweenMax.to('#mane-0-b', 1.5, {
         attr: {
            d:
               'm31.1 18.8 c0 0 9 -6.3 25.1 -5.8 l-14.7 7.1 c0 0 8.4 1.2 19.8 1.9 l-11.9 3.9 c0 0 4.2 2.6 11.8 5.1l-8 1.2 c0 0 6.3.4 8.7 6.2 l-5.5-.4 c0 0 2.5 5.9 5.5 9.3 l-5.2-3 c0 0 3 5.1 5.2 10.1 l-5.4-1.6 c2.5 5.9 4.8 8.4 4.8 8.4 -13.5 -4 -30.2 -43 -30.2 -43',
         },
         repeat: -1,
         yoyo: true,
      })

      TweenMax.to('#mane-1', 1, {
         attr: {
            d:
               'm29.4 20.4c0 0 11.9-5.4 28.7-1.6l-18.2 3.6 c0 0 12.2 1.6 19.6 5.8 h-12.2 c0 0 7.3 1.7 10.6 7.1l-6.9-.7c0 0 7.5 3.2 10.5 9.6l-7.3-3.8c0 0 5.8 3.1 5 13.4l-4.2-8.8c0 0 1.6 10.7 4.8 13.2l-4.4-.1c2 2.2 3 3.9 3 3.9 h-10.2 l-18.7-41.6',
         },
         repeat: -1,
         yoyo: true,
      })

      TweenMax.to('#mane-2-a', 2, {
         attr: {
            d: 'm23 18 c0 0 2.7 11 13.5 14.6 2 2 -26.6 -5.8 -13.5 -14.6',
         },
         repeat: -1,
         yoyo: true,
      })

      TweenMax.to('#mane-2-b', 1, {
         attr: {
            d:
               'm31.1 18.8 c0 0 6.8 18 20.8 23.5 -13.8 -13.2 -10.4 -20.6 -21.4 -25.8 0 0 0 1.3 .6 2.8',
         },
         repeat: -1,
         yoyo: true,
      })
   })

   return (
      <svg
         style={{ opacity: 0.8 }}
         xmlns="http://www.w3.org/2000/svg"
         viewBox="0 0 64 64"
         enableBackground="new 0 0 64 64"
      >
         <path
            id="horn-0"
            d="m2 2l4.4 5.9c.3-.6.7-1.2 1-1.9l-5.4-4"
            fill="#eff6f7"
         />
         <path
            id="horn-1"
            d="m9.1 11.5c.7-.9 1.4-1.8 2-2.7l-3.6-2.7c-.3.6-.7 1.2-1 1.9l2.6 3.5"
            fill="#eff6f7"
         />
         <path
            id="horn-2"
            d="m11.6 14.9c1.1-1.1 2.2-2.2 3.3-3.3l-3.8-2.8c-.7.9-1.4 1.8-2 2.7l2.5 3.4"
            fill="#eff6f7"
         />
         <path
            id="horn-3"
            d="m14.1 18.2c1.6-1.2 3.2-2.4 4.8-3.6l-3.9-2.9c-1.1 1.1-2.2 2.2-3.3 3.3l2.4 3.2"
            fill="#eff6f7"
         />
         <path
            id="horn-4"
            d="m16.4 21.3c2.2-1.2 4.4-2.5 6.5-3.7l-4.1-3.1c-1.6 1.2-3.2 2.4-4.8 3.6l2.4 3.2"
            fill="#eff6f7"
         />
         <path
            id="horn-5"
            d="m23 17.6c-2.2 1.2-4.4 2.5-6.5 3.7l3.9 5.2 4.9-4.9 1.4-1.4-3.7-2.6"
            fill="#eff6f7"
         />
         <g id="mane-0" fill="#ff639b">
            <path
               d="m32.3 17.8c0 0 4.2-7.1 22.2-8.8-18.3-2-23.5 4.6-23.5 4.6s.7 2.7 1.3 4.2"
               id="mane-0-a"
            />
            <path
               d="m31.1 18.8c0 0 9-6.3 25.1-5.2l-14.7 7.1c0 0 8.4 1.2 19.8 1.9l-11.9 3.9c0 0 4.2 2.6 11.8 5.1l-8 1.2c0 0 6.3.4 8.7 6.2l-5.5-.4c0 0 2.5 5.9 5.5 9.3l-5.2-3c0 0 3 5.1 5.2 10.1l-5.4-1.6c2.5 5.9 4.8 8.4 4.8 8.4-13.5-4-30.2-43-30.2-43"
               id="mane-0-b"
            />
         </g>
         <path
            id="mane-1"
            d="m29.4 20.4c0 0 11.9-5.4 28.7-1.6l-18.2 3.6c0 0 12.2.6 19.6 5.8h-11.7c0 0 7.3 1.7 10.6 7.1l-6.9-.7c0 0 7.5 3.2 10.5 9.6l-7.3-3.8c0 0 5.8 3.1 5 13.4l-4.2-8.8c0 0 1.6 10.7 4.8 13.2l-4.4-.1c2 2.2 3 3.9 3 3.9h-10.8l-18.7-41.6"
            fill="#fca9c9"
         />
         <path
            id="ear"
            d="m28.2 19c0 0-.6-4.4 2.6-10.9 0 0 4.1 5.1 5.1 14.2l-7.7-3.3"
            fill="#b2c1c0"
         />
         <path
            id="head"
            d="m10.4 53.9c1.5 1 5-3 9-3.7 15-2.8 12.2-8.6 12.2-8.6 4.1 5.1-2.2 17.1-5.8 20.4h30.4c-.2-40-25.1-43.2-25.1-43.2-.8-7.2-7-10.8-7-10.8-1.9 4.5-1 12.5-1 12.5-3.1 5.4-18.9 20.3-20.7 22.5-1.8 2.2 3.8 8.8 3.8 8.8 1.5.2 3.4 1.5 4.2 2.1"
            fill="#eff6f7"
         />
         <g id="mouth-chin" fill="#b2c1c0">
            <path d="m15.1 52c.8-.6 2.7-1.8 5.6-2.4 2-.4 3.9-1 5.7-1.8 1.8-.8 3.4-1.9 4.4-3.6.9-1.6.9-3.7.2-5.5 1.1 1.6 1.5 3.9.5 5.9-.9 2-2.8 3.3-4.7 4.1-1.9.9-3.9 1.4-6 1.7-2.4.3-4.7 1.2-5.7 1.6" />
            <path d="m6.2 45.8c-.5.4-1.8.6-2.2.1-.4-.5.3-1.6.8-1.9.5-.4 1.2-.3 1.6.2.4.6.3 1.3-.2 1.6" />
            <path d="m15.9 43.1c-.1 2.3-1.3 4.6-3.1 6.2-.9.8-2 1.5-3.1 1.9-1.1.4-2.4.6-3.6.6 2.3-.7 4.4-1.7 6-3.2.8-.7 1.5-1.6 2.2-2.5.6-.9 1.2-1.9 1.6-3" />
         </g>
         <path
            id="eye"
            d="m16.4 35.2c0 0 .9-.7 1.7-.5 0 0-1.1 1.1-.3 2.9 0 0 .9-2.1 2.5-2.2 0 0-1.1 1.5.3 2.9 0 0 .3-2.1 2.3-2.8 0 0-.3 1.1.7 2.5 0 0-.3-1.9 1.1-2.9 1.4-.9 3-1.5 3.7-3.8 0 0-3.4 3.5-8.1 2.5-1.1-.4-2.9-.7-3.9 1.4"
            fill="#3e4347"
         />
         <g id="ear-nose" fill="#b2c1c0">
            <path d="m25.4 21.6c-1.7-2.2-.6-8.4-.4-9.3.2-1 5.8 2.8 4.3 9.5-.1.6-.4 1.2-1 1.2-.6 0-.6-1.7-.3-2.8.3-1.1-.4 0-.6.7-.3.8-.5.2-.4-.6.1-.8-.6.8-.4 1.7.1.9 0 1.1-1.2-.4" />
            <path d="m5 42.1c-.5.3-.4.5 0 .3.5-.2 2.7-.7 2.9 1.6 0 0 .1 1.1.4 0 .3-1-.3-3.7-3.3-1.9" />
         </g>
         <g id="mane-2" fill="#ff639b">
            <path
               d="m23 18c0 0 2.7 11 13.5 16.6 0 0-26.6-5.8-13.5-16.6"
               id="mane-2-a"
            />
            <path
               d="m31.1 18.8c0 0 6.8 18 20.8 23-13.8-13.2-10.4-20.6-21.4-25.8 0 0 0 1.3.6 2.8"
               id="mane-2-b"
            />
         </g>
         <path
            id="neck"
            d="m31.7 41.6c0 0 6.3 4.1 1.7 20.4h-7.5c0 0 9.3-11.2 5.8-20.4"
            fill="#b2c1c0"
         />
      </svg>
   )
}

export default Unicorn
