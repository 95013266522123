import React, { Fragment, useEffect } from 'react'
import AOS from 'aos'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import styled, { createGlobalStyle } from 'styled-components'
import { Grid, Cell } from 'styled-css-grid'

import BackImage from '../components/background-image'
import Header from '../components/header'
import Footer from '../components/footer'
import SEO from '../components/seo'
import Unicorn from '../components/unicorn'

const StyledGrid = styled(Grid)`
   min-height: 100vh;
   padding-top: 10px;
   padding-bottom: 200px;
   margin-left: 10vw;
   margin-right: 10vw;

   @media only screen and (max-width: 800px) {
      grid-template-columns: auto;
      grid-template-areas:
         'topLeft'
         '.';
   }
`

const StyledArticleWithBorder = styled.article`
   border: 1px solid var(--image-border-color);
   width: 100%;
   padding: 10px;
`

const StyledArticleNoBorder = styled.article`
   width: 100%;
   padding: 10px;
`

const StyledParagraph = styled.p`
   text-transform: uppercase;
   font-size: 72px;
   line-height: normal;
   color: var(--text-color);
   margin-top: 72px;
`

const getFluidImages = (data, filename) =>
   data.allFile.edges
      .filter(({ node }) => node.relativePath === filename)
      .map(({ node }) => node.childImageSharp.fluid)[0]

const getFluidBackgroundImage = data => data.file.childImageSharp.fluid

const GlobalStyles = createGlobalStyle`
    body {
        font-family: 'Odibee Sans', sans-serif;
    }
`

const IndexPage = ({ data }) => {
   useEffect(() => AOS.init({ duration: 800 }))

   const sunglassesImage = getFluidImages(data, 'sunglasses.jpg')
   const wallImage = getFluidImages(data, 'wall.jpg')
   const slideImage = getFluidImages(data, 'slide.jpg')
   const icecreamImage = getFluidImages(data, 'icecream.jpg')
   const backgroundImage = getFluidBackgroundImage(data)

   return (
      <Fragment>
         <SEO title="DAYNA.ZONE - Home" />
         <GlobalStyles />
         <Header />

         <BackImage fluid={backgroundImage}>
            <StyledGrid columns={2} areas={['topLeft .']}>
               <Cell area="topLeft">
                  <StyledArticleNoBorder>
                     <StyledParagraph>
                        DAYNA ISN&#39;T IN THE ZONE YET.. SO, IN THE
                        MEANTIME, HERE ARE SOME PICTURES OF DAYNA AND HER
                        BROTHER FROM WHEN THEY WERE YOUNGER.
                     </StyledParagraph>
                  </StyledArticleNoBorder>
               </Cell>

               <Cell>
                  <Unicorn />
               </Cell>

               <Cell className="grow">
                  <StyledArticleWithBorder data-aos="fade-left">
                     <Img
                        fluid={slideImage}
                        backgroundColor={`#49DDC7`}
                        fadeIn={true}
                        alt="At the top of a slide in a park."
                     />
                  </StyledArticleWithBorder>
               </Cell>

               <Cell className="grow">
                  <StyledArticleWithBorder data-aos="fade-right">
                     <Img
                        fluid={sunglassesImage}
                        backgroundColor={`#49DDC7`}
                        fadeIn={true}
                        alt="Wearing sunglasses."
                     />
                  </StyledArticleWithBorder>
               </Cell>

               <Cell className="grow">
                  <StyledArticleWithBorder data-aos="fade-up">
                     <Img
                        fluid={icecreamImage}
                        backgroundColor={`#49DDC7`}
                        fadeIn={true}
                        alt="Eating an ice cream."
                     />
                  </StyledArticleWithBorder>
               </Cell>

               <Cell className="grow">
                  <StyledArticleWithBorder data-aos="fade-up">
                     <Img
                        fluid={wallImage}
                        backgroundColor={`#49DDC7`}
                        fadeIn={true}
                        alt="Walking along a wall."
                     />
                  </StyledArticleWithBorder>
               </Cell>
            </StyledGrid>

            <Footer />
         </BackImage>
      </Fragment>
   )
}

export const query = graphql`
   query IndexQuery {
      allFile {
         edges {
            node {
               id
               relativePath
               childImageSharp {
                  fluid(
                     maxWidth: 960
                     quality: 100
                     toFormat: WEBP
                     duotone: { highlight: "#49DDC7", shadow: "#000000" }
                  ) {
                     ...GatsbyImageSharpFluid
                  }
               }
            }
         }
      }
      file(relativePath: { eq: "2010-grey.jpg" }) {
         childImageSharp {
            fluid(
               maxWidth: 2000
               quality: 100
               toFormat: WEBP
               duotone: { highlight: "#01F9FF", shadow: "#000000" }
            ) {
               ...GatsbyImageSharpFluid
            }
         }
      }
   }
`

export default IndexPage
