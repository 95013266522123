import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'
import { Grid, Cell } from 'styled-css-grid'
import GitHubIcon from '../assets/github.svg'
import TwitterIcon from '../assets/twitter.svg'

const StyledHeader = styled.header`
   background: var(--header-bg-color);
   border-bottom: 2px solid var(--header-border-color);
   padding-top: 1.5rem;
   padding-bottom: 1.5rem;
   z-index: 1;
   //    position: fixed;
   //    top: 0;
   //    left: 0;

   h1 {
      margin: 0;
      color: var(--foreground-highlight-color);

      span {
         opacity: 0.7;
      }
      span:hover {
         opacity: 1;
      }
   }
`

const StyledGitHubIcon = styled(GitHubIcon)`
   width: 40px;
   height: 40px;
   margin-left: 10px;
   margin-right: 10px;
   color: var(--background-highlight-color);

   opacity: 0.2;

   :hover {
      opacity: 1;
   }
`

const StyledTwitterIcon = styled(TwitterIcon)`
   width: 50px;
   height: 60px;
   margin-left: 10px;
   margin-right: 10px;
   color: var(--background-highlight-color);

   opacity: 0.2;

   :hover {
      opacity: 1;
   }
`

const Header = ({ siteTitle }) => (
   <StyledHeader>
      <Grid columns={12}>
         <Cell left={2} width={5}>
            <h1>
               <span>{siteTitle}</span>
            </h1>
         </Cell>
         <Cell left={7} width={5} style={{ textAlign: 'right' }}>
            <a
               href="https://github.com/daynamcc"
               target="_blank"
               rel="noreferrer"
            >
               <StyledGitHubIcon alt="GitHub repositories" title="GitHub" />
            </a>
            <a
               href="https://twitter.com/daynamcclure"
               target="_blank"
               rel="noreferrer"
            >
               <StyledTwitterIcon alt="Twitter tweets" title="Twitter" />
            </a>
         </Cell>
      </Grid>
   </StyledHeader>
)

Header.propTypes = {
   siteTitle: PropTypes.string,
}

Header.defaultProps = {
   siteTitle: `dayna.zone`,
}

export default Header
