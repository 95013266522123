import React from 'react'
import BackgroundImage from 'gatsby-background-image'
import styled from 'styled-components'

const StyledBackgroundImage = styled(BackgroundImage)`
   background-attachment: fixed;
   background-position: center top;
   min-height: 100vh;
   width: 100%;
   height: 100%;
`

const BackImage = ({ fluid, children, className }) => (
   <StyledBackgroundImage
      Tag="div"
      className={className}
      backgroundColor={`#49DDC7`}
      fluid={fluid}
      fadeIn="soft"
   >
      {children}
   </StyledBackgroundImage>
)

export default BackImage
