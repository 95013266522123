import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

const StyledFooter = styled.footer`
   background: var(--footer-bg-color);
   padding: 2.5rem 1rem;

   span {
      opacity: 0.4;
      color: white;
   }

   span:hover {
      opacity: 0.9;
      color: var(--background-highlight-color);
   }
`

const Footer = ({ text }) => (
   <StyledFooter>
      <span>{text}</span>
   </StyledFooter>
)

Footer.propTypes = {
   text: PropTypes.string,
}

Footer.defaultProps = {
   text: `Copyright © ${new Date().getFullYear()} build like beavers®`,
}

export default Footer
